import React from 'react';
import ThreeDotsCollapse from '../ThreeDotsCollapse';

const TechniqueMethodology = () => (
  <div className="container p-4">
    <h1 className="h4">
      Μεθοδολογία απόκτησης/εκμάθησης της Τεχνικής
    </h1>
    <p>
      Για το Tennis Learning System, η απόκτηση και η εξειδίκευση της Τεχνικής, αποτελεί τον βασικότερο πυλώνα εξέλιξης. Βασιζόμενοι στα επιστημονικά στοιχεία των σύγχρονων ερευνών σχετικά με την κινητική μάθηση και απόδοση, οργανώνουμε ένα καλά δομημένο πλαίσιο αρχών μέσα στο οποίο εκτελείται η μαθησιακή διαδικασία. <br/>
      Ως  διαδικασία απόκτησης/εκμάθησης της Τεχνικής, δηλαδή ως διαδικασία δημιουργίας τεχνικών στερεοτύπων, ορίζουμε την συνολική διαδικασία συνένωσης των κινητικών αρχέγονων/στερεοτύπων (κινητικά δομικά κομμάτια), με σκοπό την δημιουργία νέων εξειδικευμένων για τον σκοπό της νέας αυτής κίνησης στερεοτύπων (χτυπήματα).<br/>
      Πιστεύουμε, ότι το σύστημα αυτό αποτελεί το πιο εξειδικευμένο σύστημα απόκτησης/εκμάθησης και ποιοτικής αξιολόγησης της Τεχνικής από οποιοδήποτε άλλο σύστημα μάθησης του τένις.
    </p>
    <h2 className="h4">
      Το πλαίσιο της απόκτησης – εκμάθησης της Τεχνικής
    </h2>
    <p>
      Είναι πλέον κοινά αποδεκτό, ότι το ανθρώπινο σώμα εκτελεί τις κινήσεις του
      συνθέτοντας κινητικά αρχέγονα δηλαδή βασικά δομικά κινητικά τμήματα.
      Επιλέγοντας τα αναγκαία τμήματα, τοποθετώντας τα στην σωστή σειρά και
      καθορίζοντας την δυναμική εκτέλεση, δημιουργεί μετά από μία διαδικασία
      εκμάθησης σταθεροποίησης και βελτιστοποίησης που στην βιβλιογραφία αναφέρεται
      ως συσσωμάτωση, νέα κινητικά στερεότυπα/αρχέγονα. Έτσι θα λέγαμε ότι η
      τμηματική εκμάθηση είναι κατά κάποια έννοια επιβεβλημένη από τη λειτουργία
      του σώματός μας. <ThreeDotsCollapse target_id="collapse-1"/>
    </p>
    <div className="collapse" id="collapse-1">
      <p>
        Στο Tennis Learning System, κατά τη διαδικασία απόκτησης της τεχνικής, ορίζουμε τα τμήματα κάθε κινητικής δεξιότητας (τεχνικής) και εν συνεχεία εκτελούμε ξεκινώντας από το βασικότερο και θεμέλιο τμήμα κάθε χτυπήματος. Κάθε νέο τμήμα προστίθεται μετά τη σχετική σταθεροποίηση του προηγούμενου. (δομημένη εκμάθηση)
      </p>
      <p>
        Σύμφωνα με τις έρευνες, η οριοθέτηση της κατεύθυνσης αποτελεί την πρώτη κυρία διαδικασία για την εκμάθηση κάθε κινητικής δεξιότητας. Έτσι σε κάθε χτύπημα, η εκμάθηση του τμήματος της επαφής που περιλαμβάνει καθαρά την κατεύθυνση του διανύσματος κάθε χτυπήματος, αποτελεί τη θεμέλια λίθο.
      </p>
        Έχει επιστημονικά αποδειχθεί, ότι ο ορισμός της αρχικής και τελικής θέσης κάθε κίνησης, βοηθάει  στην εξεύρεση της κατάλληλης τροχιάς αλλά και στη βελτιστοποίηση της εκτέλεσης της κίνησης. Έτσι, σε κάθε άσκηση ορίζουμε με τη μεγαλύτερη δυνατή ακρίβεια την αρχική και την τελική θέση. 
      <p>
        Η τελική θέση (ισορροπία, σχετική θέση του χεριού με τον κορμό, σχετική θέση των αξόνων των ώμων και των ισχίων, σχετική θέση του κορμού με την καθετότητα), είναι καθοριστικής σημασίας, γιατί μας παρέχει τη δυνατότητα αξιολόγησης της κίνησης.
      </p>
      <p>
        Καθ’ όλη την διαδικασία απόκτησης/εκμάθησης της τεχνικής, ειδικές ασκήσεις εκτελούνται με σκοπό την βέλτιστη κατανόηση και εκτέλεση των κινητικών προτύπων. Όλες οι ασκήσεις βοηθούν στην ευκολότερη και βέλτιστη συσσωμάτωση των δομικών κινητικών στοιχείων.
      </p>
    </div>
    <h2 className="h4">
      Διαδικασία απόκτησης της Τεχνικής  -  Εκμάθησης των τεχνικών προτύπων
    </h2>
    <p>
      Η διαδικασία απόκτησης της Τεχνικής πραγματοποιείται:
    </p>
    <ol>
      <li>
        Με έναν ενσυνείδητο και κατανοητό (αντιληπτικό) τρόπο που έχει  ως απώτερο
        σκοπό την πλήρη κατανόηση των κινητικών προτύπων. 
      </li>
      <li>
        Τμηματικά (κομμάτι - κομμάτι) βασιζόμενη στην έννοια των κινητικών δομικών τμημάτων. 
      </li>
      <li>
        Με μία διαδικασία Μονής Γραμμής. Δηλαδή με μία προκαθορισμένη σειρά ενεργειών
        (κάθε αντικείμενο με την σειρά του) που υπαγορεύεται από την αξιολόγηση των
        κινησιολογικών αρχών και των κινητικών δομικών τμημάτων.
      </li>
      <li>
        Σε ένα ευνοϊκό - μη απειλητικό - περιβάλλον που βρίσκεται πάντα σε συνάρτηση
        με την ικανότητα και το επίπεδο του παίκτη.
      </li>
      <li>
        Η εκμάθηση του προτύπου πραγματοποιείται βασιζόμενη στις θεμελιώδεις αρχές της μάθησης:
        <ul>
          <li>
            από τα εύκολα στα δύσκολα
          </li>
          <li>
            από τα απλά στα σύνθετα
          </li>
          <li>
            από τα μικρά στα μεγάλα
          </li>
          <li>
            από τα βασικά στα λεπτομερή
          </li>
          <li>
            και σε συνδυασμό 
          </li>
        </ul>
        με τις κινησιολογικές παραδοχές ότι μια κίνηση είναι πιο εύκολα ελεγχόμενη όταν: 
        <ul>
          <li>παίρνουν μέρος λιγότερα τμήματα </li>
          <li>εκτελείται με λιγότερους βαθμούς ελευθερίας</li>
          <li>εκτελείται πλησιέστερα στο κέντρο βάρους</li>
          <li>εκτελείται με την κατάλληλη ταχύτητα</li>
          <li>έχει καλά καθορισμένο αρχικό και τελικό σημείο</li>
        </ul>
      </li>
      <li>
        Η διαδικασία της εκμάθησης είναι τμηματική και βαθμιαία αυξανόμενης
        δυσκολίας, ξεκινώντας με την εκμάθηση του βασικότερου στοιχείου της
        κίνησης (κατεύθυνση). Μετά την σταθεροποίηση αυτού του κυριαρχικού
        τμήματος, η κινητική μάθηση επεκτείνεται (περισσότερα τμήματα,
        μεγαλύτερες κινήσεις, περισσότερη ταχύτητα, εκτελέσεις εν κινήσει  κοκ.) 
      </li>
      <li>
        Ο ρυθμός εκμάθησης ακολουθεί την ικανότητα του παίκτη.
      </li>
      <li>
        Κατά  τα αρχικά στάδια διάφορες βοηθητικές ενέργειες λαμβάνουν μέρος
        ώστε ο παίκτης να αντιληφθεί, να αισθανθεί και να εκτελέσει την κίνηση.
        Ενέργειες όπως:
        <ul>
          <li>
            εκτελέσεις της κίνησης χωρίς την μπάλα (ιδιοδεκτική  κατανόηση), 
          </li>
          <li>
            εκτελέσεις χτυπημάτων με την ταυτόχρονη βοήθεια του προπονητή
            (ο προπονητής οδηγεί το χέρι του παίκτη, κιναίσθηση)
          </li>
          <li>
            χρησιμοποιούνται παραδειγματικά γνωστές φυσικές κινήσεις που εκτελούνται
            στην καθημερινότητα.
          </li>
        </ul>
      </li>
      <li>
        Καθ’ όλη την διαδικασία της εκμάθησης της τεχνικής χρησιμοποιούνται ειδικά
        σχεδιασμένες, ασκήσεις που βοηθούν τόσο την εκμάθηση των βασικών τμημάτων
        όσο και την συσσωμάτωση των συνδυαστικών κινήσεων περισσοτέρων μελών.
      </li>
    </ol>
    <h3 className="h4 mt-5">
      Ασκησιολόγιο για την απόκτηση/εκμάθηση της Τεχνικής
    </h3>
    <p>
      Κατά την εκμάθηση της τεχνικής αφού ορίσουμε με τη μεγαλύτερη δυνατή ακρίβεια
      τα Κινητικά Πρότυπα (χτυπήματα) μία σειρά ασκήσεων σχεδιάζονται με σκοπό τη
      συστηματική απόκτηση/εκμάθηση και τελειοποίησή τους.
    </p>
  </div>
)

export default TechniqueMethodology;
