import React from 'react';
import BioHelmet from '../Helmets/BioHelmet';

const StathisCharitonidis = () => (
  <div className="container p-4">
    <BioHelmet />
    <div className="row">
      <div className="col">
        <h1 className="h4">Στάθης Χαριτωνίδης</h1>
        <p>
          Ο Στάθης Χαριτωνίδης  γεννήθηκε στην Αθήνα το 1967
        </p>
        <p>
          To 1985 αποφοίτησε από το Λεόντειο Λύκειο Πατησίων και εισήχθη στο <strong>Εθνικό και Καποδιστριακό Πανεπιστήμιο Αθηνών</strong> απ’ όπου αποφοίτησε με πτυχίο Προπονητή Αντισφαίρισης.
        </p>
        <p>
          Με το Τένις ασχολήθηκε από το 1980 σε ηλικία 13 ετών,  μετέχοντας στην 1η σχολή  του τότε <strong>Ομίλου Αντισφαίρισης Βούλας</strong> που μετονομάστηκε το 1983 στον γνωστό σήμερα  <strong>Όμιλο Αντισφαίρισης Γλυφάδας</strong>. <br/>
        Σε πολύ μικρό διάστημα, κατάφερε να έχει αρκετά σημαντικές διακρίσεις μετέχοντας στην Εθνική Ομάδα κάτω  των 18 μόλις 3 χρόνια μετά το 1983 σε ηλικία 16 ετών, ενώ το 1984 κατέκτησε  το Πανελλήνιο Πρωτάθλημα κάτω των 18 ετών και  το Πανελλήνιο Πρωτάθλημα στο Διπλό Ανδρών<br/>
          Το 1984 και το 1985 έφτασε στα ημιτελικά του Πανελληνίου Πρωταθλήματος Ανδρών στο Μονό.
        </p>
        <p>
          Από το  1985 και σε ηλικία μόλις 18 ετών, ανέλαβε ως Επικεφαλής  προπονητής του ομίλου του, δηλαδή του Ομίλου Αντισφαίρισης Γλυφάδας  με πρώτους αθλητές του τους συναθλητές του, <strong>Γιώργο Φουντούκο, Αμάντα Μητροπούλου, Ελπίδα Κώλλ, και Νικήτα Γκάλτσο</strong> κ.α. με του οποίους κατέλαβε τρείς 2ες και μια 3η θέση στο Πανελλήνιο Πρωτάθλημα Εφήβων του 1986. Από τότε και μέχρι το 1996, με μια μικρή διακοπή ενός περίπου έτους εργάστηκε ως  προπονητής στον Όμιλο Αντισφαίρισης Γλυφάδας με αρκετές διακρίσεις. Με συνεργάτες τον Γιώργο Φουντούκο και την Στέλλα Στεφανίδου οργάνωσε τον όμιλο Γλυφάδας σε έναν από τους ενεργητικότερους ομίλους στην αθλητική δραστηριότητα των παίδων και των εφήβων. Αν και κανένας αθλητής του δεν κατέλαβε το Πανελλήνιο Πρωτάθλημα, αρκετοί έφτασαν σ τα ημιτελικά και αγωνίστηκαν με την εθνική ομάδα της κατηγορίας τους.
        </p>
        <p>
          Από το 1996 έως το 1997 υπηρέτησε την στρατιωτική του θητεία.
        </p>
        <p>
          Από τον Νοέμβριο  του 1997 - οπότε και απολύθηκε από τον στρατό - μέχρι τον Ιούνιο του 1998 εργάστηκε στον Α.Ο.Ταταύλα.
        </p>
        <p>
          Τον Ιούλιο του 1998 ανέλαβε Επικεφαλής προπονητής  του <strong>Ομίλου Αντισφαίρισης Αθηνών</strong> όπου και εργάστηκε μέχρι τον Ιούλιο του 2009. Αυτή υπήρξε και η πιο παραγωγική περίοδος του. Ανέλαβε τον όμιλο σε μια κατάσταση μειωμένης αγωνιστικής δραστηριότητας με μόλις μια αθλήτρια στην 4άδα του Πανελληνίου Πρωταθλήματος της χρονιάς εκείνης και κατάφερε μαζί με τους συνεργάτες του <strong>Δημήτρη Πατσουράκη, Δήμητρα Χατζηγεωργίου</strong> και μετέπειτα με τον <strong>Σωτήρη Νάνο</strong> εντός μικρού σχετικά διαστήματος να καταστήσει τον Όμιλο Αθηνών σε κυρίαρχο αθλητικά όμιλο για μια σχεδόν 10ετία. Μέσα σε αυτό το διάστημα, ένδεκα (11) αθλητές του ομίλου κέρδισαν περισσότερα από δεκαπέντε  (15) Πρωταθλήματα Ελλάδος σε όλες τις κατηγορίες και πολλοί ακόμα είχαν σημαντικές διακρίσεις.
        </p>
        <p>
          Αθλητές όπως οι:
          <ul>
            <li>
              <strong>Γερασίμου Άννα</strong> (ν. 230 WTA, Πρωταθλήτρια Ελλάδος Γυναικών, Πρωταθλήτρια Ελλάδος κάτω των 16 & 18 ετών)
            </li>
            <li>
              <strong>Γλέζος Μανώλης</strong> (πρωταθλητής Ελλάδος κάτω των 18, 2ος στο Πανελλήνιο Πρωτάθλημα Ανδρών)
            </li>
            <li>
              <strong>Γεωργάτου Ειρήνη</strong> (πρωταθλήτρια Ελλάδος κάτω των 14 ετών)
            </li>
            <li>
              <strong>Βεντουρής Βασίλης</strong> (πρωταθλητής Ελλάδος κάτω των 18 ετών)
            </li>
            <li>
              <strong>Γιαννακάκης Ηλίας</strong> (πρωταθλητής Ελλάδος κάτω των 16 ετών)
            </li>
            <li>
              <strong>Κόκκοτας Νίκος</strong> (πρωταθλητής Ελλάδος κάτω των 16 ετών)
            </li>
            <li>
              <strong>Τριάντης Αποστόλης</strong> (πρωταθλητής Ελλάδος κάτω των 16 ετών)
            </li>
            <li>
              <strong>Τριάντης Άκης</strong> (πρωταθλητής Ελλάδος κάτω των 16 ετών)
            </li>
            <li>
              <strong>Παπαγεωργίου Ειρήνη</strong> (πρωταθλήτρια Ελλάδος κάτω των 12 ετών)
            </li>
            <li>
              <strong>Μπουρλάκου Αναστασία</strong> (πρωταθλήτρια Ελλάδος κάτω των 12 ετών)
            </li>
            <li>
              <strong>Καΐρη  Αγγελική</strong> (πρωταθλήτρια Ελλάδος κάτω των 10 ετών)
            </li>
            <li>
              <strong>Σαρλή Όλγα</strong> (3η θέση στο Πανελλήνιο Πρωτάθλημα Γυναικών 2007 ετών)
            </li>
            <li>
              <strong>Μαργαρίτη Σύλβια</strong> (3η θέση στο Πανελλήνιο Πρωτάθλημα κορίτσια 18 ετών)
            </li>
            <li>
              <strong>Κουμάντου Άννα</strong> (3η θέση  στο Πανελλήνιο Πρωτάθλημα κορίτσια 18 ετών)
            </li>
            <li>
              <strong>Σωτηριάδου Ασπασία</strong> (2η θέση στο Πανελλήνιο Πρωτάθλημα κορίτσια 16 ετών)
            </li>
            <li>
              <strong>Νάνος Σωτήρης</strong>  (3η θέση στο Πανελλήνιο Πρωτάθλημα αγόρια 16 ετών)
            </li>
            <li>
              <strong>Μαυρόπουλος Βλαδίμηρος</strong> (3η θέση στο Πανελλήνιο Πρωτάθλημα αγόρια 16)
            </li>
            <li>
              <strong>Μακρομαρίδου Λίλα</strong> (3η θέση στο Πανελλήνιο Πρωτάθλημα κορίτσια 16 ετών)
            </li>
            <li>
              <strong>Γιαννακάκη Βασιλική</strong> (3η θέση στο Πανελλήνιο Πρωτάθλημα κορίτσια  16 ετών)
            </li>
            <li>
              <strong>Κόκκοτα Αγγελική</strong> (3η θέση στο Πανελλήνιο Πρωτάθλημα κορίτσια 12 ετών)
            </li>
          </ul>
        </p>
        <p>
          Κατά την διάρκεια της παρουσίας του ο Όμιλος κατακτούσε σταθερά μια από τις δύο πρώτες θέσεις στο Διασυλλογικό Πρωτάθλημα Ανδρών Γυναικών. Το 2008 με ομάδες που αποτελούντο κατά τα 7/8 από αθλητές του προπονητικού του προγράμματος και κατά τα 5/8 από εφήβους κατέκτησε ταυτόχρονα την 1η θέση τόσο των Ανδρών όσο και των Γυναικών.
        </p>
        <p>
          Από το 2010 έως το 2012 εργάστηκε ως επικεφαλής προπονητής  χωρίς σημαντικές διακρίσεις στον Α.Ο.Α.Αργυρούπολης.
        </p>
        <p>
          Τον Ιανουάριο του 2013 προσελήφθη  ως επικεφαλής προπονητής στον Ο.Α.Πετρούπολης και κατάφερε σε πολύ μικρό χρονικό διάστημα να δημιουργήσει τις συνθήκες ώστε να ολοκληρωθεί με επιτυχία όλη η χρονιά. Εντυπωσιακό είναι το γεγονός ότι ο Όμιλος κατατάχθηκε  1ος  στο Πανελλήνιο Πρωτάθλημα Εφήβων Παίδων της χρονιάς εκείνης κερδίζοντας δύο 1ες μια 2η και δύο 3ες θέσεις, ενώ ο αθλητής του ομίλου και πρωταθλητής Ελλάδος κάτω των 14 ετών για το 2013 Κωστάρας Παναγιώτης έχασε μόλις έναν αγώνα την χρονιά εκείνη κερδίζοντας το Πανελλήνιο Πρωτάθλημα, πέντε Πανελλαδικά και τρία Διεθνή Πρωταθλήματα στην κατηγορία του (κάτω των 14 ετών).
        </p>
        <p>
          Το 2014 ανέλαβε ως επικεφαλής προπονητής στον  Ο.Α.Βάρης επιτυγχάνοντας μια 2η θέση κάτω των 10 ετών στο Πανελλήνιο Πρωτάθλημα του 2015 με τον αθλητή Δούσκο Γιώργο ενώ δύο ακόμα μικρές αθλήτριες βρεθήκαν στην 4αδα  και την 8αδα της κατηγορίας τους στην Πανελλήνια κατάταξη.
        </p>
        <p>
          Από το Ιούνιο του 2017 έως τον Ιούνιο του 2019 συνεργάστηκε  με τον Ο.Α.Πάρου σε έναν νέο ρόλο ως Τεχνικός και Εκτελεστικός Διευθυντής
        </p>
        <p>
          Πέραν των συνεργασιών του με τους ομίλους έχει εργαστεί σε όλους σχεδόν τους τομείς του αθλήματος όπως προσωπικός προπονητής , Διευθυντής Αγώνων  αλλά και Συντονιστής των Ball Persons στους Ολυμπιακούς Αγώνες της Αθήνας το 2004.
        </p>
        <p>
          Από τις συνεργασίες του αυτές ξεχωρίζουν:
          <ul>
            <li>
              H εργασία του ως Συντονιστής των Ball Persons στους Ολυμπιακούς Αγώνες της Αθήνας το 2004 όπου ανέλαβε την εκπαίδευση 250 παιδιών και την λειτουργία 150 παιδιών κατά την διάρκεια των αγώνων. Την επίδοση του τομέα αυτού την θεωρεί μια από τις πιο σημαντικές επιτυχίες του καθώς  κατάφερε να  είναι ο λειτουργικός τομέας που απέσπασε ιδιαίτερη επιβράβευση από την Διεθνή Ομοσπονδία του Τένις για την λειτουργία του κατά την διάρκεια των Ολυμπιακών Αγώνων.
            </li>
            <li>
              Η συνεργασία του ως προσωπικός προπονητής με την αθλήτρια Julia Salnikova –Τσιτσιπά  (ν. 195 WTA) από το 1991 έως το 1995.
            </li>
            <li>
              Η εργασία του ως προπονητής της Εθνικής Ομάδας Γυναικών για το Fed Cup το 2006 2007 & 2008.
            </li>
            <li>
              Η συνεργασία του με την αθλήτρια Άννα Γερασίμου την οποία προπόνησε για 5 χρόνια (2003-2008). Την ανέλαβε 16 ετών στα πλαίσια της εργασίας του στον Όμιλο Αθηνών και εργάστηκε και σε προσωπικό επίπεδο μαζί της μέχρι την ηλικία των 21 ετών φτάνοντας στην 8άδα του Πανευρωπαϊκού κάτω των 16 και την 3η θέση στην Ευρωπαϊκή κατάταξη της ηλικίας κάτω των 16 ετών το 2003 και έως  το 230 της παγκόσμιας κατάταξης των γυναικών WTA το 2008, επιτυγχάνοντας σε ηλικία εφήβων και νέων σημαντικές νίκες επί πολύ γνωστών μετέπειτα αθλητριών όπως η Angelique Gerber,  Lucie Safarova, Carla Suarez Navaro, Elize Cornet κα.
            </li>
          </ul>
        </p>
        <p>
          Όλα αυτά τα χρόνια έχει διδάξει ένα πολύ μεγάλο αριθμό αθλητών αλλά και προπονητών.  Ένας πολύ μεγάλος αριθμός παικτών του (περισσότεροι από 40) εργάζονται σήμερα ως προπονητές. Αρκετοί από αυτούς σε μεγάλους συλλόγους. (Ο.Α.Αθηνών, Ο.Α.Φιλοθέης, Ο.Α.Ηλιούπολης κα)
        </p>
        <p><strong>
          Ο Στάθης Χαριτωνίδης έχει βοηθήσει εργαζόμενος εθελοντικά όλα αυτά τα χρόνια ένα μεγάλο αριθμό νεαρών  παιδιών αλλά και φτασμένων αθλητών μεταξύ των οποίων οι Julia Salnikova –Τσιτσιπά , η Χριστίνα Παπαδάκη, η Ελπίδα Αλεξάνδρου,  η Άννα Γερασίμου,  ο Αποστόλης Τριάντης κα.
        </strong></p>
        <p>

        </p>
      </div>
    </div>
  </div>
)

export default StathisCharitonidis;
