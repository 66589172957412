import React from 'react';
import Helmet from 'react-helmet';

const BioHelmet = () => (
  <Helmet>
    <title>Στάθης Χαριτωνίδης</title>
    <meta
      name="description"
      content="Ο Στάθης Χαριτωνίδης έχει διατελέσει προπόνητής πολλών μεγάλων ομίλων και της Εθνικής Ομάδας Γυναικών. Ένας μεγάλος αριθμός παικτών του έχουν κατακτήσει περισσότερα από 15 Πανελλήνια Πρωταθλήματα σε όλες σχεδόν τις κατηγορίες, καθώς διεθνή επαγγελματικά τουρνουά μέχρι και 25.000 δολλαρίων."/>
    <meta
      name="keywords"
      cotent="Στάθης Χαριτωνίδης, τένις, the coach, tennisnews coach, Στάθης, Χαριτωνίδης, προπονητές τένις, προπονητής, αντισφαίρισης, μαθήματα τένις, όμιλος τένις, tennis club">
    </meta>

  </Helmet>
)

export default BioHelmet;
