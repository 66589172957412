import React from 'react';
import FAQuestion from '../FAQuestion';

const TechniqueDefinition = () => (
  <div className="container p-4">
    <h1 className="h4">
      Ο καθορισμός και η σημασία των Τεχνικών Προτύπων  στo Tennis Learning System
    </h1>
    <p>
      Σήμερα, παρόλο που όλο και περισσότερες επιστημονικές μελέτες και έρευνες
      σχετικές με την Εμβιομηχανική, δηλαδή την ανάλυση της Τεχνικής, συντάσσονται
      και δημοσιεύονται, κανένα καθολικά αποδεκτό Τεχνικό πρότυπο δεν έχει αναπτυχθεί. 
      Αντιθέτως, η σημασία της Τεχνικής μοιάζει να περιορίζεται και η προπόνηση
      αποκτά όλο και περισσότερο  - και από πολύ αρχικά στάδια - τα χαρακτηριστικά
      της προσαρμογής.
      Κακοπροσαρμογής  ίσως ακριβέστερα. Τελικά την κυρίαρχη διαδικασία απόκτησης
      της Τεχνκής αναλαμβάνει ο μιμητισμός.
    </p>
    <p>
      Στο Tennis Learning System πιστεύουμε ότι ο λεπτομερής καθορισμός των Τεχνικών
      Προτύπων έχει ιδιαίτερη σημασία για την εκμάθηση της Τεχνικής
    </p>
    <ul>
      <li>
        Η απόκτηση της Τεχνικής(εκμάθηση) βασίζεται πλήρως στην ξεκάθαρη κατανόηση
        των τεχνικών απαιτήσεων για κάθε παράμετρο της απόδοσης (χτυπήματα, κινήσεις,
          μετακινήσεις, τακτική).
      </li>
      <li>
        Οι τεχνικές απαιτήσεις για κάθε δεξιότητα υπολογίζονται και καταγράφονται.
      </li>
      <li>
        Καθορίζονται τα Τεχνικά πρότυπα με βάση τις Κινησιολογικές αρχές (Εμβιομηχανική, Λειτουργική Ανατομική).
      </li>
      <li>
        Τα πρότυπα αυτά παραμένουν πάντα τα σημεία αναφοράς για την Τεχνική αξιολόγηση.
      </li>
      <li>
        Ειδικά σχεδιασμένες ασκήσεις χρησιμοποιούνται για την εκμάθησης αλλά και την αξιολόγηση της Τεχνικής
      </li>
      <li>
        Σε αντίθεση με την κοινή αντίληψη,  η ενσυνείδητη απόκτηση/εκμάθηση και
        προσαρμογή των Τεχνικών Προτύπων  στις εκάστοτε δυσκολίες δεν αποτελεί
        ένα μικρό αλλά ένα βασικό μέρος της προπόνησης, καταλαμβάνοντας κατ’ αναλογία
        μεγαλύτερο μέρος στα αρχικά και μικρότερο μέρος στα τελικά στάδια της μάθησης
      </li>
      <li>
        Σε κάθε επίπεδο εκτελούνται  κατ’ αναλογία προγράμματα διατήρησης της Τεχνικής 
      </li>
    </ul>
    <h2 className="h4 mt-5">
      Ερωτήσεις και απαντήσεις σχετικά με τα Τεχνικά Πρότυπα.
    </h2>
    <div id="accordion" className="mt-3">
      <FAQuestion
        id={3}
        question="Πιο είναι το πρόβλημα με την κυρίαρχη μεθοδολογία απόκτησης της Τεχνικής και Προπόνησης  σήμερα;"
        answer="Το πρόβλημα με την κυρίαρχη μεθοδολογία της Τεχνικής σήμερα, είναι
        ότι κατά τα αρχικά στάδια, ένας σχετικά μεγάλος αριθμός τεχνικών
        λαθών δημιουργείται και ακόμα χειρότερα σταθεροποιείται, καθώς
        επαναλαμβάνεται επί μακρόν. 
        Τα λάθη αυτά στην τεχνική των χτυπημάτων, δημιουργούν διαστρεβλωμένα
        τεχνικά μοντέλα, που περιορίζουν την δυναμική των παικτών και αυξάνουν
        τους τραυματισμούς. Στα αρχικά στάδια, αυτό δεν γίνεται άμεσα αντιληπτό
        καθώς από την μια πλευρά το επισκιάζει η αρχική ραγδαία πρόοδος και από
        την άλλη οι εντάσεις και κατ΄επέκταση και οι επιβαρύνσεις είναι ακόμα
        μικρές και βραχυχρόνιες. Το πρόβλημα γίνεται πιο εμφανές σε ψηλότερα επίπεδα,
        όπου ακόμα και οι ελάχιστες λεπτομέρειες δημιουργούν καθοριστικές διαφορές
        στην απόδοση."
      />
      <FAQuestion
        id={1}
        question="Γιατί κυριαρχεί ο μιμητισμός;"
        answer="Δεν υπάρχει μια καθολικά κατανοητή και αποδεκτή επιστημονική βάση
        δεδομένων. Ως συνέπεια, ακόμα και πολύ απλά και βασικά τεχνικά στοιχεία,
        δεν είναι καθολικά καθορισμένα και καθίστανται αντικείμενα γνωμών και απόψεων.
        Δεν υπάρχει ένα καθολικά αποδεκτό τεχνικό πλαίσιο. Πολύ περισσότερο,
        δεν υπάρχει ένα κοινό αποδεκτό τεχνικό πρότυπο για κάθε χτύπημα."
      />
      <FAQuestion
        id={2}
        question="Γιατί ο μιμητισμός δεν αρκεί για την πλήρη τεχνική κατανόηση;"
        answer="Επειδή τα σημαντικά τεχνικά στοιχεία του χτυπήματος πραγματοποιούνται
        κατά την διάρκεια των γρήγορων τμημάτων της κίνησης και έτσι είναι
        δύσκολο να παρατηρηθούν. Ο παρατηρητής – που συνήθως είναι κάποιος
        αρχάριος – είναι βέβαιο ότι δεν έχει το απαιτούμενο γνωστικό επίπεδο,
        ώστε να ξεχωρίσει τα κύρια στοιχεία και να τα μιμηθεί. Αν και έχουμε
        σκοπό να αντιγράψουμε την Τεχνική, αντιγράφουμε τελικά κυρίως τις
        ατομικές χαρακτηριστικές κινήσεις (στιλ) και όχι τα σημαντικά τεχνικά
        στοιχεία του χτυπήματος (όπως είναι ο ρυθμός, ο συγχρονισμός, η
        χρησιμοποίηση της ελαστικής ενέργειας, η λειτουργία της κινηματικής
        αλυσίδας κ.α.)"
      />
      <FAQuestion
        id={4}
        question="Πως λειτουργεί το σώμα μας;"
        answer="Αντίθετα με την κοινή αντίληψη, οι κινήσεις του ανθρωπίνου σώματος, δεν
        είναι ούτε άπειρες ούτε σχηματικά (γεωμετρικά) ακαθόριστες. Συντίθενται από πολύ
        συγκεκριμένα και απόλυτα καθορισμένα κινητικά στερεότυπα που ονομάζονται κινητικά
        αρχέγονα. Αυτά τα κινητικά αρχέγονα αποτελούν τα δομικά τμήματα (κινητικά δομικά
        κομμάτια) των πολύπλοκων κινήσεων και εκτελούνται μέσα από μια επίσης πολύ
        συγκεκριμένη δυναμική αλλά και νευρική λειτουργία (δυναμικά και νευρικά αρχέγονα).
        Η δημιουργία κινητικών αλληλουχιών, εμπεριέχει την επιλογή, την σειριακή
        τοποθέτηση και το χρονισμό των απλούστερων κινητικών στερεοτύπων. Μέσα από
        την μακροχρόνια προπόνηση, τα κινητικά αυτά αρχέγονα , αφού εκτελεστούν συνδυαστικά
        για ένα ικανό χρονικό διάστημα, συσσωματώνονται για να δημιουργήσουν τελικά ένα νέο
        κατάλληλο για τον σκοπό κινητικό αρχέγονο (χτύπημα)."
      />
      <FAQuestion
        id={5}
        question="Τι είναι αυτό που λέμε Τεχνική;"
        answer="Είναι η εφαρμογή όλων των καλά επιβεβαιωμένων επιστημονικών δεδομένων
        στην πράξη με σκοπό την δημιουργία μιας αλληλουχίας που να είναι η πλέον αποδοτική
        για τον συγκεκριμένο σκοπό. Βασιζόμενοι στην μέχρι σήμερα γνώση, μπορούμε να
        είμαστε σίγουροι για όλα σχεδόν τα σημαντικά στοιχεία της Τεχνικής. Τόσο σε
        ότι αφορά στο σχήμα, αλλά και σε ότι αφορά στα κινηματικά (ρυθμός, ανατομικές
        σχέσεις) και τα κινητικά (δυναμικά) δεδομένα της εκτέλεσης του χτυπήματος.
        Είναι πλέον ευκρινώς αποδεδειγμένο, ότι η Τεχνική δεν μπορεί να είναι οι προσωπικές
        απόψεις ή η μίμηση των αστέρων του τένις."
      />
      <FAQuestion
        id={6}
        question="Τι είναι Τεχνικό Πρότυπο; "
        answer="Ως Τεχνικό πρότυπο καθορίζουμε μια αλληλουχία  κινήσεων με ένα
        γεωμετρικό σχήμα, συγκεκριμένες  σχετικές κινήσεις μεταξύ των μελών του
        σώματος και  τοπολογικά καθορισμένη δυναμική ανάπτυξη."
      />
    </div>
  </div>
)

export default TechniqueDefinition;
