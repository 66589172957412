import './App.css';

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import HomeHelmet from './Components/Helmets/HomeHelmet';

import ScrollToTop from './Components/ScrollToTop';
import NavBar from './Components/NavBar';
import Banner from './Components/Banner';
import Footer from './Components/Footer';

import Home from './Components/Pages/Home';
import About from './Components/Pages/About';
import Contact from './Components/Pages/Contact';
import Lessons from './Components/Pages/Lessons';
import Bio from './Components/Pages/Bio';
import SpecialProgrammes from './Components/Pages/SpecialProgrammes';
import FAQ from './Components/Pages/FAQ';
import StathisCharitonidis from './Components/Pages/StathisCharitonidis';
import TechniqueDefinition from './Components/Pages/TechniqueDefinition';
import TechniqueMethodology from './Components/Pages/TechniqueMethodology';
import TrainingMethodology from './Components/Pages/TrainingMethodology';
import CorrectionsMethodology from './Components/Pages/CorrectionsMethodology';

export default function App() {
  return (
    <Router>
      <HomeHelmet />
      <ScrollToTop />
      <NavBar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/technique-definition">
            <TechniqueDefinition />
          </Route>
          <Route exact path="/technique-methodology">
            <TechniqueMethodology />
          </Route>
          <Route exact path="/training-methodology">
            <TrainingMethodology />
          </Route>
          <Route exact path="/corrections-methodology">
            <CorrectionsMethodology />
          </Route>
          <Route exact path="/lessons">
            <Lessons />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/bio">
            <Bio />
          </Route>
          <Route exact path="/FAQ">
            <FAQ />
          </Route>
          <Route exact path="/special-programmes">
            <SpecialProgrammes />
          </Route>
          <Route exact path="/StathisCharitonidis">
            <StathisCharitonidis />
          </Route>
          <Route>
            <Home />
          </Route>
        </Switch>
        <Footer />
    </Router>
  );
}
