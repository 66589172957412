import React from 'react';
import { Link } from 'react-router-dom';
import ThreeDots from './ThreeDots';
import strairs_img from '../images/stairs.webp';

const SpecialProgrammesIntro = () => (
  <div className="row">
    <div className="col-md-4 col-sm-12 px-1 text-center">
      <img src={strairs_img} alt="" height="auto" width="100%"></img>
    </div>
    <div className="col-md-8 col-sm-12 my-auto">
      <Link to="/special-programmes">
        <h1 className="h4 text-center">
          Εξειδικευμένα προγράμματα τεχνικής βελτίωσης
        </h1>
        </Link>
        <p className="justify">
          Τα εξειδικευμένα προγράμματα είναι η καρδιά του Tennis Learning System.
          Για όσους ενδιαφέρονται να βελτιωθούν, να διορθώσουν κάποιο χτύπημά τους,
          να  παίξουν καλύτερα, χωρίς τραυματισμούς και τελικά πολύ πιο ευχάριστα.
          Εκτελούμε προγράμματα Ποιοτικής Εμβιο-Μηχανικής Ανάλυσης και Τεχνικής Βελτίωσης,
          εφαρμόζοντας την Επιστήμη στην πράξη για όλες τις ηλικίες και όλα τα επίπεδα.
          <ThreeDots to="/special-programmes" />
        </p>
    </div>
  </div>
)

export default SpecialProgrammesIntro;
