import React from 'react';
import { Link } from 'react-router-dom';

const ThreeDots = (props) => (
  <Link to={props.to}>
    <svg width="3em" height="1.3em" viewBox="0 0 10 10" className="bi bi-three-dots" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
    </svg>
  </Link>
)

export default ThreeDots;
