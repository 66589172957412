import React from 'react';
import { Link } from 'react-router-dom';
import ThreeDots from '../ThreeDots';
import HomeHelmet from '../Helmets/HomeHelmet';

import thecoach from '../../images/charitonidis-thecoach.jpg';
import forehand_analysis from '../../images/forehand-analysis.png';

const Home = () => (
  <div>
    <HomeHelmet />
    <Servers />
    <div className="container p-4">
      <div className="row">
        <div className="col-sm-12 col-md-9">
          <h1 className="h4 text-center">Τι είναι το Tennis Learning System;</h1>
          <p className="justify">
            Είναι μια επιστημονική προσέγγιση για τη μάθηση στο τένις.
            Το tennis learning system είναι ένα καλά οργανωμένο και δομημένο σύστημα μάθησης
            που αναπτύχθηκε μέσα από μία μακροχρόνια πρακτική και θεωρητική αναζήτηση
            περισσότερων των 30 ετών από το δημιουργό του, Στάθη Χαριτωνίδη.
            Βασίζεται στην Ενσυνείδητη Σκόπιμη προπόνηση (Deliberate Practice) και
            την Αντιληπτική Μάθηση  και κατανόηση (Perceptual Learning).
            Ενσωματώνει στοιχεία από τη σύγχρονη έρευνα σχετικά με την απόκτηση
            και την προπόνηση πολύπλοκων κινήσεων όπως είναι αυτές του τένις
            και σκοπεύει στη μεγιστοποίηση της απόδοσης μέσα από την λεπτομερή
            βελτιστοποίηση όλων των διαδικασιών της προπόνησης.
            Έτσι απαντά τελικά στα περισσότερα "Γιατί με αυτό τον τρόπο;" από
            οποιοδήποτε άλλο σύστημα εκμάθησης τένις.<br/>
            <strong>Απώτερος σκοπός της μαθησιακής
              διαδικασίας, είναι να γίνει τελικά ο κάθε παίκτης, προπονητής του εαυτού του.
            </strong>
          </p>
        </div>
        <div className="col-sm-12 col-md-3 text-center my-auto">
          <Link to="/stathischaritonidis">
            <img src={thecoach} alt="" width="auto" height="200px"></img>
          </Link>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <Link to="/lessons">
            <h2 className="h4 text-center">Μαθήματα και προπονήσεις</h2>
          </Link>
          <p className="justify">
            Στο Tennis Learning System εφαρμόζοντας με απλοποιημένο και κατανοητό
            τρόπο την επιστήμη στην πράξη δημιουργούμε ένα συνολικό σύστημα μάθησης
            για όλες τις ηλικίες και όλα τα επίπεδα. Το σύστημα αυτό είναι εξίσου
            αποδοτικό τόσο για προγράμματα με σκοπό την αναψυχή όσο και κυρίως
            για προγράμματα με σκοπό τη βέλτιστη απόδοση
            <ThreeDots to="/lessons"/>
        </p>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-sm-12 col-md-4">
          <img src={forehand_analysis} alt="" width="80%" height="auto"></img>
        </div>
        <div className="col-sm-12 col-md-8">
          <Link to="/special-programmes">
            <h2 className="h4 text-center mt-5">
              Εξειδικευμένα προγράμματα τεχνικής βελτίωσης
            </h2>
          </Link>
          <p className="justify">
            Για όσους ενδιαφέρονται να βελτιωθούν, να διορθώσουν κάποιο χτύπημά τους,
            να  παίξουν καλύτερα, χωρίς τραυματισμούς και τελικά πολύ πιο ευχάριστα.
            Εκτελούμε προγράμματα Ποιοτικής Εμβιο-Μηχανικής Ανάλυσης και Τεχνικής
            Βελτίωσης, εφαρμόζοντας την Επιστήμη στην πράξη για όλες τις ηλικίες
            και όλα τα επίπεδα
            <ThreeDots to="/special-programmes" />
          </p>
            <Link to="/FAQ">
              <h3 className="h4 text-center mt-5">
                Θέλω να μάθω περισσότερα
              </h3>
            </Link>
            <p className="">
              Απαντήσεις σε εύλογα ερωτήματα για κάποιον που θέλει να μάθει τένις
              <ThreeDots to="/FAQ" />
            </p>
        </div>
      </div>
    </div>
  </div>
)

const Servers = () => (
  <div className="servers">
    <div className="servers-text-div">
      <h2 className="servers-text mx-3">
        The expertise in tennis learning.
      </h2>
    </div>
  </div>
)

export default Home;
