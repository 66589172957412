import React from 'react';
import { Link } from 'react-router-dom';
import SpecialProgrammesHelmet from '../Helmets/SpecialProgrammesHelmet';

const SpecialProgrammes = () => (
  <div className="container p-4">
    <SpecialProgrammesHelmet />
    <div className="p-md-5 text-justify">
      <h2 className="h4 text-center">
        Εξειδικευμένα προγράμματα Εμβιομηχανικής Ανάλυσης και Τεχνικής Βελτίωσης
      </h2>
      <p>
        Υπάρχουν πλέον πολλά επιστημονικά στοιχεία που βοηθούν στον καθορισμό της Τεχνικής
        και στον τρόπο προπόνησης. Για το λόγο αυτό, η Τεχνική δεν μπορεί να είναι προσωπικές
        απόψεις, παρά μόνο η εφαρμογή τεκμηριωμένων επιστημονικών δεδομένων στην πράξη.
        Εφαρμόζοντας λοιπόν τις εμβιο-μηχανικές αρχές στην πράξη αναλύουμε την κίνηση
        και καταγράφουμε τα λάθη. Eν συνεχεία τα αξιολογούμε και ορίζουμε την σειρά των
        ενεργειών για την διόρθωσή τους.<br/>
        Το πρόγραμμα έχει διάρκεια 8 ώρες και εκτελείται σε 4 φάσεις:
      </p>
      <ul className="centered-ul">
        <li className="undecorated">
          <strong>1η φάση διάρκειας 1 ώρας</strong>
          <p>Βιντεοσκόπηση και μια αρχική παρουσίαση και επεξήγηση των λαθών</p>
        </li>
        <li className="undecorated">
          <strong>2η  φάση διάρκειας 2 ωρών:</strong>
          <p>Αναλυτική μελέτη του βίντεο και καταγραφή των λαθών (χωρίς την παρουσία του παίκτη) </p>
        </li>
        <li className="undecorated">
          <strong>3η φάση διάρκειας 1 ώρας:</strong>
          <p>Παρουσίαση των λαθών, παρουσίαση και πρακτική εφαρμογή των διορθώσεων τους γήπεδο.</p>
        </li>
        <li className="undecorated">
          <strong>4η  φάση διάρκειας 4 ωρών:</strong>
          <p>4 ωριαία μαθήματα σε διάστημα 2 εβδομάδων</p>
        </li>
      </ul>
      <p>Ολοκληρώνοντας το πρόγραμμα αυτό θα έχετε μια σαφή αντίληψη των λαθών σας
        και – κυρίως - του τρόπου βελτίωσής τους.
      </p>
      <p className="">Η έδρα μας είναι το Ολυμπιακό Στάδιο της Αθήνας, αλλά μπορούμε να
        πραγματοποιήσουμε το πρόγραμμα και οπουδήποτε επιθυμείτε εσείς.
      </p>
      <div className="text-center">
        <Link to="/contact">
          <button type="button" className="m-4 btn btn-primary">Ενδιαφέρομαι</button>
        </Link>
      </div>
    </div>
  </div>
)

export default SpecialProgrammes;
