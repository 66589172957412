import React from 'react';

const ThreeDotsCollapse = (props) => (
  <a className="three-dots-collapse" data-toggle="collapse" href={"#" + props.target_id} role="button" aria-expanded="false" aria-controls={props.target_id}>
    <svg width="3em" height="1.3em" viewBox="0 0 10 10" className="bi bi-three-dots" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
    </svg>
  </a>
)

export default ThreeDotsCollapse;
