import React from 'react';
import ThreeDots from '../ThreeDots';
import { Link } from 'react-router-dom';
import TelephoneIcon from '../TelephoneIcon';
import athens_cups from '../../images/athens_cups.webp';

const Bio = () => (
  <div className="container p-4">
    <div className="p-md-5 text-center">
      <Link to="/stathischaritonidis">
        <h1 className="h4">Στάθης Χαριτωνίδης</h1>
      </Link>
      <p className="justify">
        Ο Στάθης Χαριτωνίδης έχει εργαστεί εδώ και 35 χρόνια σε όλους σχεδόν τους
        τομείς του τένις στην Ελλάδα. Είναι απόφοιτος του Πανεπιστημιού Αθηνών.
        Έχει διατελέσει προπόνητής πολλών μεγάλων ομίλων και της Εθνικής Ομάδας
        Γυναικών. Ένας μεγάλος αριθμός παικτών του (12) έχουν κατακτήσει περισσότερα
        από 15 Πανελλήνια Πρωταθλήματα σε όλες σχεδόν τις κατηγορίες, ενώ παίκτες
        του έχουν κατακτήσει διεθνή πρωταθλήματα μέχρι και 25.000 δολλαρίων.
        <ThreeDots to="/stathischaritonidis"/>
      </p>
      <img src={athens_cups} className="brightness-150" alt="" width="90%" height="auto"></img>
      <h1 className="h4 mt-5">Νίκος Πανουσάκης</h1>
      <p className="justify">
        Ο Νίκος Πανουσάκης είναι μαθηματικός, συγγραφέας και ζωγράφος. Διδάσκει Μαθηματικά
        σε ανώτερες και ανώτατες βαθμίδες της εκπαίδευσης, από παιδιά Λυκείου μέχρι και
        Διδακτορικούς φοιτητές. Είναι παλαιό μέλος της Μαθηματικής Εταιρείας και έχει εκδώσει
        6 βιβλία μαθηματικών, συνεργαζόμενος με τον εκδοτικό οίκο Κοκοτσάκη.
        Ταυτόχρονα είναι και ζωγράφος. Ένα από τα κύρια θέματά του είναι η Κίνηση,
        και ιδιαίτερα στον αθλητισμό. Έχει επανειλημμένα λάβει μέρος σε διάφορες εκθέσεις.
        Έργα του έχουν εκτεθεί  στον Φιλοτεχνικό Σύλλογο «Παρνασσός»  και έχουν δημοσιευθεί
        από το περιοδικό Tennis Society. Έχει επιμεληθεί και τα σχέδια αυτής της ιστοσελίδας.
        <a href="tel:+306946479211"><TelephoneIcon /></a>
      </p>
    </div>
  </div>
)

export default Bio;
