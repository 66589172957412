import React from 'react';
import { Link } from 'react-router-dom';
import LessonsHelmet from '../Helmets/LessonsHelmet';

import SpecialProgrammesIntro from '../SpecialProgrammesIntro';

import court_shades from '../../images/court_shades.webp';
import group_img from '../../images/group.webp';
import competitive_img from '../../images/competitive.webp';

const Lessons = () => (
  <div className="container p-4">
    <LessonsHelmet />
    <div className="text-center p-md-3 m-md-3">
      <h1 className="h4">
        Μαθήματα Tennis
      </h1>
    </div>
    <SpecialProgrammesIntro />
    <div className="row mt-5">
      <div className="col-sm-12 col-md-4 text-center mb-4">
        <img src={court_shades} alt="" className="lessons-img" height="auto" width="100%"></img>
        <h2 className="h4 mt-3">
          Ιδιαίτερα μαθήματα
        </h2>
        <p className="justify">
          Σε κάθε περίπτωση το τένις είναι ένα ατομικό άθλημα. Τα ατομικά μαθήματα
          δίνουν την δυνατότητα προσωπικά στοχευόμενης διδασκαλίας και ευελιξία στην
          τοποθεσία και την ώρα του μαθήματος.
        </p>
      </div>
      <div className="col-sm-12 col-md-4 text-center mb-4">
        <img src={group_img} alt="" className="lessons-img"></img>
        <h2 className="h4 mt-3">
          Ομαδικά μαθήματα
        </h2>
        <p className="justify">
          Τα ομαδικά μαθήματα γίνονται σε ομάδες δύο ή τριών ατόμων. Έτσι, μεγιστοποιείται
          η σχέση ποιότητας και κόστους. Έρευνες έχουν δείξει ότι υπό συγκεκριμένες
          προϋποθέσεις είναι ακόμη και πιο αποδοτικά από τα ατομικά.
        </p>
      </div>
      <div className="col-sm-12 col-md-4 text-center mb-4">
        <img src={competitive_img} alt="" className="lessons-img"></img>
        <h2 className="h4 mt-3">
          Αθλητικές προπονήσεις
        </h2>
        <p className="justify">
          Οι αθλητικές προπονήσεις αφορούν κυρίως παίκτες που έχουν ήδη αποκτήσει
          μια βασική γνώση και μπορούν να ανταπεξέλθουν σε συνθήκες ενός αγώνα.
          Μπορούν να γίνουν ατομικά ή ομδικά.
        </p>
      </div>
    </div>
    <div className="row mb-5">
      <div className="col text-center">
        <Link to="/contact">
          <button type="button" class="btn btn-primary btn-lg btn-block">Κλείστε ένα μάθημα τώρα!</button>
        </Link>
      </div>
    </div>
    <PriceTable />
  </div>
)

const PriceTable = () => (
  <div>
    <table class="table table-responsive table-striped">
    <thead>
      <tr>
        <th scope="col">Πρόγραμμα</th>
        <th scope="col">Βασική Τιμή</th>
        <th scope="col"></th>
        <th scope="col">Μέχρι τις 16:00</th>
        <th scope="col">16:00-17:00</th>
        <th scope="col">17:00-18:00</th>
        <th scope="col">18:00-22:00</th>
        <th scope="col">Μετά τις 22:00</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="col">Ιδιαίτερο Μάθημα</th>
        <td>65€/ώρα</td>
        <td></td>
        <td>45</td>
        <td>50</td>
        <td>60</td>
        <td>65</td>
        <td>50</td>
      </tr>
      <tr>
        <th scope="col">Ομαδικό Μάθημα 2 ατόμων</th>
        <td>40€/ώρα/άτομο</td>
        <td></td>
        <td>25</td>
        <td>25</td>
        <td>35</td>
        <td>40</td>
        <td>30</td>
      </tr>
      <tr>
        <th scope="col">Ομαδικό Μάθημα 3 ατόμων</th>
        <td>30€/ώρα/άτομο</td>
        <td></td>
        <td>20</td>
        <td>20</td>
        <td>25</td>
        <td>30</td>
        <td>25</td>
      </tr>
      <tr>
        <th scope="col">Πρόγραμμα Εμβιομηχανικής Ανάλυσης - Tennis Mechanic<br/>(8 ώρες)</th>
        <td>350€</td>
        <td></td>
        <td>210</td>
        <td>260</td>
        <td>300</td>
        <td>350</td>
        <td>260</td>
      </tr>
      <tr>
        <th scope="col">Πρόγραμμα Βασικών Τεχνικών Βελτιώσεων<br/>(5 ώρες)</th>
        <td>250€</td>
        <td></td>
        <td>150</td>
        <td>175</td>
        <td>200</td>
        <td>250</td>
        <td>200</td>
      </tr>
      </tbody>
    </table>
    <h3 className="h4 p-1 bg-orange text-center">Ειδικές τιμές για πακέτα πολλών μαθημάτων!</h3>
  </div>
)

export default Lessons;
