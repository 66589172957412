import React from 'react';
import Helmet from 'react-helmet';

const LessonsHelmet = () => (
  <Helmet>
    <title>Μαθήματα | Tennis Learning System</title>
    <meta
      name="description"
      content="Το tennis learning system είναι ένα καλά οργανωμένο και δομημένο σύστημα μάθησης που αναπτύχθηκε μέσα από μία μακροχρόνια πρακτική και θεωρητική αναζήτηση περισσότερων των 30 ετών από το δημιουργό του, Στάθη Χαριτωνίδη."
    />
    <meta
      name="keywords"
      cotent="μαθήματα τένις, μαθήματα tennis, tennis lessons, Στάθης Χαριτωνίδης, ομαδικά μαθήματα τένις, ιδιαίτερα tennis, όμιλος τένις, tennis club, βόρεια προάστια, ρακέτες, μπαλάκια τένις, tennis balls, εξειδικευμένα προγράμματα"
    />
  </Helmet>
)

export default LessonsHelmet;
