import React from 'react';
import { Link } from "react-router-dom";
import logo from '../images/logo.png';

const NavBar = () => (
  <div className="sticky-nav">
    <nav className="navbar navbar-fixed-top navbar-expand-lg na navbar-light bg-light">
      <Link className="navbar-brand" to="/">
        <img src={logo} alt="logo" className="logo"></img>
        Tennis Learning System
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto mt-2">
          <li className="nav-item active">
            <Link to="/">
              <p className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Αρχική<span className="sr-only">(current)</span></p>
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link  to="/about" className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Το σύστημά μας
            </Link>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <Link className="dropdown-item" to="/about">
                <p data-toggle="collapse" data-target=".navbar-collapse.show">Σχετικά</p>
              </Link>
              <Link className="dropdown-item" to="/technique-definition">
                <p data-toggle="collapse" data-target=".navbar-collapse.show">Καθορισμός της Τεχνικής</p>
              </Link>
              <Link className="dropdown-item" to="/technique-methodology">
                <p data-toggle="collapse" data-target=".navbar-collapse.show">Μεθοδολογία της απόκτησης της τεχνικής</p>
              </Link>
              <Link className="dropdown-item" to="/training-methodology">
                <p data-toggle="collapse" data-target=".navbar-collapse.show">Μεθοδολογία της προπόνησης</p>
              </Link>
              <Link className="dropdown-item" to="/corrections-methodology">
                <p data-toggle="collapse" data-target=".navbar-collapse.show">Μεθοδολογία των διορθώσεων</p>
              </Link>
              <Link className="dropdown-item" to="/FAQ">
                <p data-toggle="collapse" data-target=".navbar-collapse.show">Συχνές ερωτήσεις</p>
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <Link to="/bio">
              <p className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Εμείς</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/lessons">
              <p className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Μαθήματα</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact">
              <p className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Επικοινωνία</p>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
)

export default NavBar;
