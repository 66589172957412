import React from 'react';
import Helmet from 'react-helmet';

const HomeHelmet = () => (
  <Helmet>
    <title>Tennis Learning System</title>
    <meta
      name="description"
      content="Το tennis learning system είναι ένα καλά οργανωμένο και δομημένο σύστημα μάθησης που αναπτύχθηκε μέσα από μία μακροχρόνια πρακτική και θεωρητική αναζήτηση περισσότερων των 30 ετών από το δημιουργό του, Στάθη Χαριτωνίδη."/>
    <meta
      name="keywords"
      cotent="μαθήματα τένις, μάθημα tennis,tennis, τένις, τέννις, μαθήματα, επιστήμη, εμβιομηχανική, μάθηση, τενιστικό, Στάθης Χαριτωνίδης, προπόνηση τένις, αθληματα για παιδιά, μαθητές">
    </meta>

  </Helmet>
)

export default HomeHelmet;
