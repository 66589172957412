import React from 'react';
import Helmet from 'react-helmet';

const SpecialProgrammesHelmet = () => (
  <Helmet>
    <title>Εξειδικευμένα προγράμματα</title>
    <meta
      name="description"
      content="Για όσους ενδιαφέρονται να βελτιωθούν, να διορθώσουν κάποιο χτύπημά τους, να παίξουν καλύτερα, χωρίς τραυματισμούς και τελικά πολύ πιο ευχάριστα. Εκτελούμε προγράμματα Ποιοτικής Εμβιο-Μηχανικής Ανάλυσης και Τεχνικής Βελτίωσης, εφαρμόζοντας την Επιστήμη στην πράξη για όλες τις ηλικίες και όλα τα επίπεδα."
    />
    <meta
      name="keywords"
      cotent="Στάθης Χαριτωνίδης, ειδικά προγράμματα, εξειδικευμένα προγράμματα, αθλητική προπόνηση τένις, μαθήματα τένις, μαθήματα tennis, tennis lessons, Στάθης Χαριτωνίδης, ομαδικά μαθήματα τένις, ιδιαίτερα tennis, όμιλος τένις, tennis club, βόρεια προάστια, ρακέτες, μπαλάκια τένις, tennis balls"
    />
  </Helmet>
)

export default SpecialProgrammesHelmet;
