import React from 'react';

const FAQuestion = (props) => (
  <div className="card">
    <div className="card-header" id={"heading"+props.id}>
      <h5 className="mb-0">
        <button className="btn btn-link btn-wrap-text" data-toggle="collapse" data-target={"#collapse"+props.id} aria-expanded="true" aria-controls={"collapse"+props.id}>
          {props.question}
        </button>
      </h5>
    </div>
    <div id={"collapse"+props.id} className="collapse" aria-labelledby={"heading"+props.id} data-parent="#accordion">
      <div className="card-body">
        {props.answer}
      </div>
    </div>
  </div>
)

export default FAQuestion;
