import React from 'react';

const Footer = () => (
  <footer className="page-footer font-small blue pt-4">
    <div className="container-fluid text-center text-md-left border-top-1">
      <div className="row">
        <div className="col-6 text-left my-auto">
          <p className=""><small>This website uses cookies for analytics. By visiting, you agree to our use of cookies.</small></p>
        </div>
        <div className="col-6 pt-3 text-right">
          <p className="">Tennis Learning System</p>
          <p><small>
            Στάθης Χαριτωνίδης<br/>
            <a href="tel:+306947995758">6947995758</a><br/>
            <a href="mailto:tennislearningsystem@yahoo.com">tennislearningsystem<br/>@yahoo.com</a>
          </small></p>
        </div>
      </div>
    </div>
    <div className="footer-copyright text-center py-3">
      <p className="mb-0">© 2020 Copyright:Tennis Learning System</p>
      <p>Website created by <a href="https://github.com/haritonch">haritonch</a></p>
    </div>
  </footer>
)

export default Footer;
