import React from 'react';
import MailIcon from '../MailIcon';
import TelephoneIcon from '../TelephoneIcon';
import ContactHelmet from '../Helmets/ContactHelmet';

const Contact = () => (
  <div className="container p-5">
    <ContactHelmet />
    <div className="row">
      <div className="col text-center">
        <h3 className="">
          Στάθης Χαριτωνίδης
        </h3>
        <h4 className="mt-5">
          <a className="no-dec mx-4" href="tel:+306947995758"><TelephoneIcon /> 6947995758</a>
        </h4>
      </div>
    </div>
    <div className="row text-center mt-5">
      <div className="col">
        <a className="no-dec mx-4" href="mailto:tennislearningsystem@yahoo.com"><MailIcon /> tennislearningsystem@yahoo.com</a>
      </div>
    </div>
    <div className="row text-center mt-5">
      <div className="col">
        <h4>
          <a className="no-dec mx-4" href="https://forms.gle/mhp5s3eCgDxDZVZW9">Φόρμα επικοινωνίας</a>
        </h4>
      </div>
    </div>
  </div>
)

export default Contact;
