import React from 'react';

const CorrectionsMethodology = () => (
  <div className="container p-4">
    <h1 className="h4">
      Μεθοδολογία των Διορθώσεων
    </h1>
    <p>
      Τα λάθη περιορίζουν την εξέλιξη, δημιουργούν συχνά πεδία απόδοσης - δηλαδή καταστάσεις που η περαιτέρω βελτίωση καθίσταται αδύνατη - και αυξάνουν υπέρμετρα τους τραυματισμούς. Η προσπάθεια αύξησης της απόδοσης μέσα από την αύξηση της ενεργειακής δαπάνης - δηλαδή μέσα από την αύξηση της προσπάθειας και της δυναμικής εκτέλεσης - οδηγεί με μαθηματική ακρίβεια στην αύξηση των σοβαρών τραυματισμών. Για όλους τους παραπάνω λόγους είναι πολύ σημαντικό τα λάθη - αν δεν αποφευχθούν κατά την περίοδο της εκμάθησης - να διορθωθούν το συντομότερο δυνατόν.
    </p>
    <p>
      Οι διορθώσεις της Τεχνικής, αποτελούν την δυσκολότερη ίσως διαδικασία για κάθε τεχνικό πρόγραμμα. Τα σταθεροποιημένα, λόγω της χρήσης τους, κινητικά στερεότυπα, έχουν πολλές φορές  μεταστρέψει αυτή την ίδια την αντίληψη  του παίκτη  για το φυσιολογικό και συχνά δημιουργούν ψευδαισθήσεις ή προκαταλήψεις που δύσκολα αλλάζουν.
    </p>
    <p>
      Η οριοθέτηση, η καταγραφή και η αξιολόγηση των λαθών παίζουν πολύ σημαντικό ρόλο στην διαδικασία διόρθωσής τους. <br/>
Σε επόμενο στάδιο, ο καθορισμός της σειράς των ενεργειών, από τα κυρίαρχα στα λιγότερο σημαντικά, είναι καθοριστικής σημασίας καθώς πολλές διορθώσεις έχουν αλληλοεξαρτώμενο χαρακτήρα.
    </p>
    <p>
      Στο Tennis Learning system οι διορθώσεις της Τεχνικής ακολουθούν μια πολύ ξεκάθαρη διαδικασία που βασίζεται σε επιστημονικά στοιχεία και  περιλαμβάνει:
      <ol>
        <li>
          Την καταγραφή των λαθών μέσα από την παρατήρηση (αν χρειαστεί βιντεοσκόπηση) αλλά και την εκτέλεση Διαγνωστικών  Ασκήσεων που βασίζονται στο καθορισμένα κινητικά πρότυπα. Κατά την εκτέλεση των διαγνωστικών αυτών ασκήσεων θα βρείτε τον εαυτό σας να εκτελεί πολύ παράξενες  και αναιτιολόγητες κινήσεις.
        </li>
        <li>
          Την αξιολόγηση των λαθών. Δηλαδή ποια λάθη είναι κυρίαρχα και ποια ακόλουθα.
        </li>
        <li>
          Τον καθορισμό της σειράς των ενεργειών. Δηλαδή τι θα διορθωθεί πρώτο τι δεύτερο τι τρίτο κοκ.
        </li>
        <li>
          Τον σχεδιασμό του προγράμματος των διορθώσεων. Δηλαδή πόσος χρόνος θα χρειαστεί, πόσο συχνά πρέπει να εκτελούνται οι προπονήσεις, ποια άλλη προπονητική διαδικασία μπορεί να εκτελείται  ταυτόχρονα, ποια πρέπει να είναι η συχνότητα των επαναληπτικών προπονήσεων μετά την διόρθωση των λαθών.
        </li>
        <li>
          Την εκτέλεση του σχεδιασμένου προγράμματος
        </li>
      </ol>
    </p>
    <p>
      Στο Tennis Learning System πιστεύουμε ότι μέσα και μετά από αυτή την πολύ σύνθετη και λεπτομερή διαδικασία, όλα τα λάθη μπορούν να διορθωθούν, όχι στον ίδιο χρόνο, ούτε με την ίδια προσπάθεια, αλλά με την απαιτούμενη γνώση και  μεθοδικότητα.
    </p>
  </div>
)

export default CorrectionsMethodology;
