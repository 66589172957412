import React from 'react';

const CorrectionsMethodology = () => (
  <div className="container p-4">
    <h1 className="h4">
      Ενσυνείδητη Σκόπιμη και Μη Βεβιασμένη  Προπόνηση (Deliberate practice) 
    </h1>
    <p>
      Η διαδικασία της προπόνηση στο Tennis Learning System βασίζεται στην ενσυνείδητη σκόπιμη και μη βεβιασμένη προπόνηση.
      Αυτό, δεν σημαίνει σε καμιά περίπτωση, ότι δεν περιλαμβάνονται περίοδοι ελεύθερης προπόνησης, η ελεύθερες ανταγωνιστικές διαδικασίες. Σε κάθε περίπτωση όμως, υπολογίζεται η ποσότητα της ελεύθερης, έναντι της ενσυνείδητης και σχεδιασμένης προπόνησης και διατηρούνται οι ωφέλιμες αναλογίες.
    </p>
    <p>
      Το Tennis Learning System ακολουθεί τις πιο κάτω βασικές καθοδηγητικές γραμμές για τον σχεδιασμό και την εκτέλεση των προγραμμάτων προπόνησης:
      <ol>
        <li>
          Σε κάθε επίπεδο σεβόμαστε τις Βασικές Αρχές της Προπόνησης όπως: 
          <ul>
            <li>Προοδευτικότητα</li>
            <li>Επαναληπτικότητα </li>
            <li>Μακροχρόνιος σχεδιασμός</li>
            <li>Περιοδικότητα και κυκλικότητα</li>
            <li>Ωφελιμότητα </li>
            <li>Ανθεκτικότητα </li>
            <li>Εξάρτηση από την ηλικία</li>
            <li>Εξάρτιση από το επίπεδο </li>
            <li>Εξειδίκευση κτλ.</li>
          </ul>
        </li>
        <li>
          Τα στάδια της προπόνησης, εκτελούνται με συγκεκριμένο σχεδιασμό που ακολουθεί μία καθορισμένη σειρά ενεργειών. Αν και πάντα επιδιώκεται μια ταχύτερη μάθηση, σε καμία περίπτωση δεν καταργείται η συνέχεια της προόδου.
        </li>
        <li>
          Η πρόοδος στα αρχικά στάδια, σχεδιάζεται να επιτυγχάνεται κυρίως λόγω της βελτίωσης της σταθεροποίησης και της ωρίμανσης των τεχνικών προτύπων και λιγότερο μέσω  των αυτόματων προσαρμογών, ενώ στα τελικά στάδια αντίθετα.
        </li>
        <li>
          Η προπόνηση ακολουθεί και σέβεται τις βασικές αρχές της προπονητικής: 20-30% προπόνηση χαμηλής έντασης, 50-60% προπόνηση κανονικής έντασης, 20-30% προπόνηση υψηλής έντασης.
        </li>
        <li>
          Η ένταση της προπόνησης ακολουθεί την τεχνική ικανότητα του παίκτη και κατά αυτή την έννοια:
          <ul>
            <li>δεν προτιμώνται μοντέλα προπόνησης που δεν επιτρέπουν την τεχνική εκτέλεση κατά τουλάχιστον 70%</li>
            <li>όταν το κινητικό πρότυπο αδυνατεί να ανταποκριθεί στην ένταση της προπόνησης και καταρρέει, η προπόνηση επιστρέφει σε προγενέστερα στάδια μέχρι το μοντέλο να σταθεροποιηθεί και να είναι ικανό να ανταπεξέλθει στην σχεδιαζόμενη επιθυμητή ένταση. </li>
          </ul>
        </li>
        <li>
          Η προπόνηση μέσω τυχαίων εκτελέσεων και καταστάσεων (random practice), περιέχεται κατά ένα μέρος και αναλόγως σε όλα τα στάδια – επίπεδα και αξιολογείται ανάλογα με την ένταση της εκτέλεσης και την ικανότητα του αθλητή ως μέρος της υπό-προπόνησης, της προπόνησης ή της υπέρ-προπόνησης. 
        </li>
        <li>
          Όσο υψηλότερο το επίπεδο των αθλητών, τόσο μεγαλύτερο το μέρος της προπόνησης που καταλαμβάνει η διαδικασία τυχαίων εκτελέσεων και καταστάσεων  προσαρμογής και τόσο αυξημένη είναι η συνολική ένταση της προπόνησης.
        </li>
        <li>
          Σε όλα τα επίπεδα εκτελούνται σχετικά και αναλόγως ασκήσεις διατήρησης της τεχνικής. 
        </li>
      </ol>
    </p>
  </div>
)

export default CorrectionsMethodology;
